import React from 'react'


function MainEuropa() {

    return (
        <>
        <section className="my-favoritos back-trendy-3">
            <h1 className="text-center title-my-favoritos">Viajes Europa</h1>
          
        </section>
        
        <section className="section-top-ten">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                   
                <iframe className="iframe-mega" title="europa"  src="https://q.megatravel.com.mx/tools/vi.php?Dest=1&txtColor=000000&aColor=000000&ahColor=f8936b&thBG=ffaa16&thTxColor=fff&ff=2" width="100%" height="1200" border="0"align="left" allowtransparency="true" frameborder="0"> </iframe>
                </div> 
            </div> 
        </div>   
        </section>
     
        </>
    )
}

export default MainEuropa
